import React, { useCallback,useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Skill.module.scss";

import tb from "../../../datatable.module.scss";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import {
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineNavigateNext,
} from "react-icons/md";

// --------- Images --------- //
import studentIcon from "../../../images/icon-student.svg";
import uploadFile from "../../../images/iconUpload.svg";
import PhoneNumber from "../../../components/Phonenumber/Phonenumber";
import Checkbox from "../../../components/Forms/Checkbox";
import { AiFillDelete, AiFillHome, AiOutlineHome } from "react-icons/ai";
import DemoInfo from "../../../components/DemoInfo/DemoInfo";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

export default function AddSkill() {

  const { register, handleSubmit, reset,formState: { errors }, } = useForm();
  const navigate = useNavigate();
  const[editid, setEditid]= useState();
  const location = useLocation();


  const onSubmit = async(data:any)=>{
    console.log("editiddd", editid);

    const addJob = { 
      // id: editid,
      skillName: data.skillName, 
     }

    console.log("reactHook", addJob);

    if(editid){
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/skill/updateSkill/${editid}`,{
        method: "PATCH",
        headers:{
           Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        },
      
        body: JSON.stringify(addJob),
      });

        const result = await response.json();
        console.log("updated result....", result);
        navigate(`/skills`);
    }

    else{
      const response = await fetch("https://api.happiest.team/api/v1/skill/createSkill",{
      method: "POST",
      headers:{
        'Content-Type': 'application/json',
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      },
    
      body: JSON.stringify(addJob),
    });
    
    const result = await response.json();
    console.log(" new created result....", result);
    navigate(`/skills`);
    }

  }

  
    const getSingleData = async () => { 
      const { state } = location;
      if(state!= null){
      const id= state.key.keyidd;
      setEditid(id);
      console.log("single user id",id);

      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/skill/getSkill?id=${id}`, {
        method: 'GET',
        headers: {
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        }
      });
      const result = await response.json();
      // console.log("single user data...", result.data);
      const{skillName} = result.data;
      reset({skillName});
    }

    }
  
           
    useEffect(() => {
      getSingleData();
     },[reset]);
    
  
  
 
  return (
    <>
      <section className={`${st.pageWrapper} `}>
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={4} lg={4}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Add Skill</h5>
                  <p>Create a new Skill</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <ul className={`${st.bridSection}`}>
                <li>
                  <NavLink to="/skills"> Skills</NavLink>
                </li>
                <li>
                  <MdOutlineNavigateNext className={`${cx.icon}`} />
                </li>
                <li>
                  <span>Add</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
            <div className={`${st.contentBox}`}>
             
             <Form onSubmit={handleSubmit(onSubmit)}>
             <Row>
                   <Col lg={8} md={4}>
                     <Form.Group className={`${st.formField}`}>
                       <Form.Label>
                         {" "}
                         Skill<span className="text-danger">*</span>
                       </Form.Label>
                       <Form.Control type="text" {...register("skillName", { required: true })} placeholder="skill Name" />
                     </Form.Group>
                   </Col>

                   <Col
                     lg={4} md={8}
                     className={`${st.formField} text-end`}
                   >
                     <Form.Label className={`${st.labelHide}`}>.</Form.Label>
                     <Button type="submit" className={`${st.btnDisabled}`}>Save</Button>
                   </Col>

                 </Row>
             </Form>
        
           </div>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
