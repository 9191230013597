import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import studentIcon from "../../../images/icon-student.svg";
import {
  Card,
  Col,
  Row,
} from "react-bootstrap";

import EnrollNewStudentM from "../../../components/Modals/EnrollNewStudentM";

import AddProfile from "./AddProfile";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function CandidateDetails() {

  const stylemargin = {
    marginLeft: "100px",
    backgroundColor: "rgb(241,243,246)"
  }
  const [getUser, setUser] = useState<any>();
  const location = useLocation();
  const dataid = location.state;


  const getUserApi = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/getProfile?id=${dataid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    }).then(result => {
      // console.log(result.data.data, "uuuuuuuuuuuuuuu");
      setUser(result.data.data)
    });
  }

  useEffect(() => {
    getUserApi();
  }, [])
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`} style={stylemargin}>

        <div className={`${st.pageWrapperInside}`}>
          <Container className="py-4">
            <Card>
              <Card.Body>
                <div className={`${st.tabbingList}`}>
                  <div className={`${st.tabbingList}`}>

                    <Row className="border rounded mt-4">
                      <Col md={3} className="pt-4">
                        <div className={`${st.uploadForm} me-3 mb-3`}>
                          <div className={`${st.fileUpload} position-relative`}>
                            {
                              getUser?.profileImage ?
                                <img src={getUser?.profileImage} className={`${st.icon}`} /> :
                                <img src={studentIcon} className={`${st.icon}`} />
                            }
                          </div>
                        </div>
                      </Col>
                      <Col md={9}>
                        <div className={`me-3 mb-3`}>
                          <h2 className="mb-0 pb-0">{(getUser?.name ? getUser?.name : "") + " " + (getUser?.lastName ? getUser?.lastName : "")}</h2>
                          <small className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>ProfileTitle :</small> {getUser?.profileTitle}</small>
                          <Row className="mt-2">
                            <Col>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Email :</small> {getUser?.email}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>DOB :</small> {getUser?.dob}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Mobile :</small> {getUser?.mobile}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Type :</small> {getUser?.type}</p>
                            </Col>
                            <Col>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Address :</small> {getUser?.address}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>City :</small> {getUser?.city}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>State :</small> {getUser?.state}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Country :</small> {getUser?.country}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Zip Code :</small> {getUser?.zipCode}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <Row className="border mt-3 rounded py-2">
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Candidate _id :</small> {getUser?._id}</p>
                      </Col>
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Availability :</small> {getUser?.availability}</p>
                      </Col>
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Account Created At :</small>
                          {new Date(getUser?.createdAt).toLocaleDateString()
                            + " " +
                            new Date(getUser?.createdAt).toLocaleTimeString()}
                        </p>
                      </Col>
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Gender :</small> {getUser?.gender}</p>
                      </Col>
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Hourly Rate :</small> {getUser?.hourlyRate}</p>
                      </Col>
                      <Col md={4}>
                        <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Website :</small> {getUser?.website}</p>
                      </Col>
                    </Row>

                    <Row className="border mt-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Bio :</small> {getUser?.bio}</p>
                        <p className=""><small style={{ fontWeight: "600", fontSize: "16px" }}>Languages :</small> {getUser?.languages.split(",").join(", ")}</p>
                        <p className=""><small style={{ fontWeight: "600", fontSize: "16px" }}>Skills :</small> {getUser?.skills?.map((item: any) => item)?.join(", ")}</p>
                      </Col>
                    </Row>

                    <Row className="border mt-3 rounded py-2" >
                      <Col md={6}>
                        {/* <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Followers :</small> {getUser?.followers?.length}</p> */}
                        <p className=""><small style={{ fontWeight: "600", fontSize: "16px" }}>Following :</small> {getUser?.following?.length}</p>
                      </Col>
                      <Col md={6}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Saved Jobs :</small> {getUser?.savedJobs?.length}</p>
                        {/* <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Saved Companies :</small> {getUser?.savedCompanies?.length}</p> */}
                      </Col>
                    </Row>

                    <Row className="border p-3 mt-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Category :</small></p>
                        {
                          getUser?.category?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h5>{item?.categoryName}</h5>
                              {
                                item?.subCategories?.map((sub: any) => {
                                  if (sub?.subCategoryName)
                                    return sub?.subCategoryName.split(",").map((subCat: any) => <button className="btn btn-light border rounded-3 py-0 me-2 mb-2" disabled>{subCat}</button>)
                                })
                              }
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Education :</small></p>
                        {
                          getUser?.Education?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.title}</h4>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Academy :</small> {item?.academy}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>StartYear :</small> {item?.startYear}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endYear}</p>
                              <p className="pb-0 mb-0 mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Description :</small> {item?.description}</p>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Work Experience :</small></p>
                        {
                          getUser?.workExperience?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.title}</h4>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Company :</small> {item?.company}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Start Year :</small> {item?.startYear}</p>
                              <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endYear}</p>
                              <p className="pb-0 mb-0 mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Description :</small> {item?.description}</p>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Certificate :</small></p>
                        {
                          getUser?.certificate?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.title}</h4>
                              <div className="row">
                                <div className="col-md-4">
                                  <img src={item?.link} height={"200px"} style={{ width: "-webkit-fill-available" }} alt="" />
                                </div>
                                <div className="col-md-8">
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Skill :</small> {item?.skill}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Start Year :</small> {item?.startYear}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endYear}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Portfolio :</small></p>
                        {
                          getUser?.portFolio?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.title}</h4>
                              <div className="row">
                                <div className="col-md-4">
                                  <img src={item?.imgLink} height={"200px"} style={{ width: "-webkit-fill-available" }} alt="" />
                                </div>
                                <div className="col-md-8">
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Link :</small> {item?.link}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Start Year :</small> {item?.startYear}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endYear}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Training :</small></p>
                        {
                          getUser?.Trainings?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.trainingProgram}</h4>
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Organization :</small> {item?.Organization}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Location :</small> {item?.Location}</p>
                                </div>
                                <div className="col-md-6">
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Start Year :</small> {item?.startDate}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endDate}</p>
                                </div>
                              </div>
                              <p className="pb-0 mb-0 mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Description :</small> {item?.Description}</p>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <Row className="border mt-3 p-3 rounded py-2" >
                      <Col md={12}>
                        <p className="mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Education :</small></p>
                        {
                          getUser?.Projects?.map((item: any) =>
                            <div className="position-relative mb-3 border-2 border-gray border-start ps-2">
                              <h2 className="position-absolute" style={{ top: "-15px", left: "-15px" }}>.</h2>
                              <h4>{item?.title}</h4>
                              <div className="row">
                                <div className="col-12">
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Project Link :</small> {item?.Link}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>Start Year :</small> {item?.startDate}</p>
                                  <p className="pb-0 mb-0"><small style={{ fontWeight: "600", fontSize: "16px" }}>End Year :</small> {item?.endDate}</p>
                                  <p className="pb-0 mb-0 mt-2"><small style={{ fontWeight: "600", fontSize: "16px" }}>Description :</small> {item?.Description}</p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </Col>
                    </Row>

                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </section>
    </>
  );
}


