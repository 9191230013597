import React, { useCallback, useState, useEffect } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./JobCategory.module.scss";

import tb from "../../../datatable.module.scss";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import {
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineNavigateNext,
} from "react-icons/md";

// --------- Images --------- //
import studentIcon from "../../../images/icon-student.svg";
import uploadFile from "../../../images/iconUpload.svg";
import PhoneNumber from "../../../components/Phonenumber/Phonenumber";
import Checkbox from "../../../components/Forms/Checkbox";
import { AiFillDelete, AiFillHome, AiOutlineHome } from "react-icons/ai";
import DemoInfo from "../../../components/DemoInfo/DemoInfo";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NestedSkills from "./NestedSkills";

export default function AddJobCategory() {

  const { register, control, handleSubmit, reset, formState: { errors }, } = useForm({
    defaultValues: {
      categoryName: "",
      subCategories: [{ subCategoryName: "" ,skills:[{skillName:''}]}],
    }
  }
  );
  const navigate = useNavigate();
  const [editid, setEditid] = useState();
  const location = useLocation();

 
  const { fields, append, remove } = useFieldArray({
    control,
    name: "subCategories"
  });
  

  const onSubmit = async (data: any) => {
    console.log("editiddd", editid);

    const addJob = data

    console.log("reactHook", data);

    if (editid) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/category/createJobCategory`, {
        method: "post",
        headers: {
          Accept: "application/json",
          'Content-Type': 'application/json',
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        },

        body: JSON.stringify(addJob),
      });

      const result = await response.json();
      console.log("updated result....", result);
      navigate(`/job-category`);
    }

    else {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/category/createJobCategory`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        },

        body: JSON.stringify(addJob),
      });

      const result = await response.json();
      console.log(" new created result....", result);
      navigate(`/job-category`);
    }

  }


  const getSingleData = async () => {
    const { state } = location;
    if (state != null) {
      const id = state.key.keyidd;
      setEditid(id);
      console.log("single user id", id);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/category/getJobCategory?id=${id}`, {
        method: 'GET',
        headers: {
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        }
      });
      const result = await response.json();
      const { name } = result.data;
    }
  }


  useEffect(() => {
    getSingleData();
  }, [reset]);


  return (
    <>
      <section className={`${st.pageWrapper} `}>

        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={4} lg={4}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Add Job Category</h5>
                  <p>Create a new job category</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <ul className={`${st.bridSection}`}>
                <li>
                  <NavLink to="/job-category"> Job Category</NavLink>
                </li>
                <li>
                  <MdOutlineNavigateNext className={`${cx.icon}`} />
                </li>
                <li>
                  <span>Add</span>
                </li>
              </ul>
            </Col>
            <Col md={4} lg={4}>
              <div className="d-flex justify-content-end">
                <DemoInfo />
              </div>
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>

              <div className={`${st.contentBox}`}>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg={8} md={4}>
                      <Form.Group className={`${st.formField}`}>
                        <Form.Label>
                          {" "}
                          Job Category<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control type="text" {...register("categoryName", { required: true })} placeholder=" Job Category" />
                      </Form.Group>
                    </Col>

                    <ul>
                      {fields.map((item, index) => (
                        <li className=" border mb-2 p-2" key={item.id}>

                          <Col className="d-flex w-100 align-items-center gap-3" lg={8} md={4}>
                            <Form.Group className={`${st.formField} w-100`}>
                              <Form.Label>
                                {" "}
                                Job Category<span className="text-danger"></span>
                              </Form.Label>
                              
                              <Form.Control
                                type="text"
                                {...register(`subCategories.${index}.subCategoryName`, { required: true })}
                                placeholder="Sub Category"
                              />
                            </Form.Group>
                            <Button type="button" onClick={() => remove(index)} className={`${st.btnDisabled}  mt-3`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                              </svg>
                            </Button>
                          </Col>
                            <NestedSkills nestIndex={index} {...{ control, register }} />
                        </li>
                      ))}
                    </ul>

                    <Col
                      md={12}
                      className={`${st.formField} d-flex justify-content-between my-3`}
                    >
                      <Button type="button" onClick={() => append({ subCategoryName: "",skills:[{skillName:''}] })} className={`${st.btnDisabled} mt-2`}>Add SubCategory</Button>
                      <Button type="submit" className={`${st.btnDisabled}`}>Save</Button>
                    
                    </Col>
                  </Row>
                </Form>

              </div>

            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
