import axios from "axios";
import Select from 'react-select';
import cx from "./Employer.module.scss";
import { NavLink } from "react-router-dom";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MdOutlineNavigateNext } from "react-icons/md";
import studentIcon from "../../../images/icon-student.svg";
import { Card, Row, Col, Form, Button, Tab, Nav, } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getImgUrl, getIndustry, getSkill, getCategory } from "../../../CommonApi/CommonApi";

// City
interface genderType {
    readonly value: string;
    readonly label: string;
}

// currentlyLookingOption
const hourlyRateOption: readonly genderType[] = [
    { value: "Less Then 15$", label: "Less Then 15$" },
    { value: "15$ - 25$", label: "15$ - 25$" },
    { value: "25$ - 35$", label: "25$ - 35$" },
    { value: "35$ - 45$", label: "35$ - 45$" },
    { value: "more then 45$", label: "more then 45$" },
];

const countryOptions: readonly genderType[] = [
    { value: 'country1', label: 'country1' },
    { value: 'country2', label: 'country2' },
    { value: 'country3', label: 'country3' }
]
const stateOptions: readonly genderType[] = [
    { value: 'state1', label: 'state1' },
    { value: 'state2', label: 'state2' },
    { value: 'state3', label: 'state3' }
]
const cityOptions: readonly genderType[] = [
    { value: 'city1', label: 'city1' },
    { value: 'city2', label: 'city2' },
    { value: 'city3', label: 'city3' }
]

const projectSizeData: readonly genderType[] = [
    { value: "Less then 5K", label: "Less then 5K" },
    { value: "5K - 10K", label: "5K - 10K" },
    { value: "10K - 20K", label: "10K - 20K" },
    { value: "20K - 30K", label: "20K - 30K" },
    { value: "greater then 30K", label: "greater then 30K" },
];



export default function EditEmployer() {

    const [editid, setEditid] = useState();
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState<any>([]);
    const { register, handleSubmit, control, reset, formState: { errors }, } = useForm();
    const location = useLocation();
    const [candidateImage, SetCandidateImage] = useState();
    const [jobSCategoryData, setJobSCategoryData] = useState<any>([])
    const [img1, setImg1] = useState<any>([]);
    const [candidateTempImage, SetCandidateTempImage] = useState<string>();

    // for update
    const [showAccordion, setShowAccordion] = useState<any>()
    const [service, setService] = useState<any>([]);
    const [serviceSkills, setServiceSkills] = useState<any>([]);
    const [errorCategory, setCategoryError] = useState('');

    const selectCategory = (e: any, category: any, subcategory: any) => {
        if (e.target.checked) {
            setService((prevService: any) => {
                const tempService = [...prevService];
                const findingCategoryIndex = tempService.findIndex(item => item?._id === category?.cid);
                let findingCategory = tempService[findingCategoryIndex];
                if (findingCategory) {
                    if (!Object.isExtensible(findingCategory.subCategories)) {
                        findingCategory = {
                            ...findingCategory,
                            subCategories: [...findingCategory.subCategories]
                        };
                    }
                    const subcategoryExists = findingCategory.subCategories.some((subcat: any) => subcat?._id === subcategory?._id);
                    if (!subcategoryExists) {
                        findingCategory.subCategories.push(subcategory);
                    }
                }
                else {
                    const newCategory = {
                        categoryName: category.cname,
                        _id: category.cid,
                        subCategories: [subcategory],
                    };
                    tempService.push(newCategory);
                }
                tempService.length === 0 ? setCategoryError("Select at least one category & subCategories") : setCategoryError("");
                return tempService;
            });
        } else {
            setService((prevService: any) => {
                const tempService = [...prevService];
                const findingCategoryIndex = tempService.findIndex(item => item?._id === category?.cid);
                let findingCategory = tempService[findingCategoryIndex];
                // Object.assign(findingCategory); // true
                if (findingCategory) {
                    const subcategoryIndex = findingCategory.subCategories.findIndex((subcat: any) => subcat?._id === subcategory?._id);
                    if (subcategoryIndex !== -1) {
                        findingCategory.subCategories.splice(subcategoryIndex, 1);
                    }
                    if (findingCategory.subCategories.length === 0) {
                        tempService.splice(findingCategoryIndex, 1);
                    }
                }
                tempService.length === 0 ? setCategoryError("Select at least one category & subCategories") : setCategoryError("");
                return tempService;
            });
        }

    }

    useEffect(() => {
        service?.forEach((item: any) => {
            item?.subCategories?.forEach((sub: any) => {
                sub?.skills?.forEach((s: any) => {
                    if (serviceSkills?.length) {
                        serviceSkills.push({ value: s?.skillName, label: s?.skillName })
                    } else {
                        setServiceSkills([...serviceSkills, { value: s?.skillName, label: s?.skillName }]);
                    }
                });
            });
        });
    }, [service, serviceSkills])


    const setShowHideAccordion = (item: any) => {
        setShowAccordion((prevShowAccordion: any) => {
            if (prevShowAccordion?.includes(item)) {
                return prevShowAccordion?.filter((data: any) => data !== item);
            } else {
                if (prevShowAccordion) {
                    return [...prevShowAccordion, item];
                } else {
                    return [item];
                }
            }
        });
    };

    const {
        fields: fieldsPortFolio,
        append: appendPortFolio,
        remove: removePortFolio
    } = useFieldArray({
        control,
        name: "portFolio",
    });

    const changeLinkOne = async (e: any, i: any) => {
        const img = e.target?.files[0];
        let body = new FormData();
        body.append("image", img);
        const result = await getImgUrl("api/v1/utils/getimagelink", body)
        // console.log(result.link, "result?.link");
        img1[i] = result?.link;

    }
    const deletePortfolio = (index:any) => {
        img1.splice(index, 1);
        removePortFolio(index);
      }

    const handleFileSelect = async (e: any) => {
        const img = e.target?.files[0];
        if (img) {
            const reader = new FileReader();
            reader.onloadend = () => {
                SetCandidateTempImage(reader.result as string);
            };
            reader.readAsDataURL(img);
        }
    };

    const onSubmit = async (data: any) => {
        let formData = new FormData();
        candidateTempImage && formData.append("profileImage", data.profileImage[0]);
        data.name && formData.append("name", data.name);
        data.name && formData.append("lastName", data.lastName);
        data.name && formData.append("email", data.email);
        data.name && formData.append("password", data.password);


        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitData = async (data: any) => {
        console.log("editiddd", editid);

        let formData = new FormData();
        data.profileTitle && formData.append("profileTitle", data.profileTitle);
        data.mobile && formData.append("mobile", data.mobile);
        data.aboutCompany && formData.append("aboutCompany", data.aboutCompany);
        data.website && formData.append("website", data.website);
        data.address && formData.append("address", data.address);

        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitDataAvailability = async (data: any) => {
        console.log("editiddd", editid);

        let formData = new FormData();

        data.projectSize && formData.append("projectSize", data.projectSize.value);
        data.minimumHourlyRate && formData.append("minimumHourlyRate", data.minimumHourlyRate.value);

        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitAddress = async (data: any) => {
        console.log("editiddd", editid);
        let formData = new FormData();
        data.address && formData.append("address", data.address);
        data.city.value && formData.append("city", data.city.value);
        data.country.value && formData.append("country", data.country.value);
        data.state.value && formData.append("state", data.state.value);
        data.zipCode && formData.append("zipCode", data.zipCode);
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitskills = async (data: any) => {
        console.log("skill", editid);
        let formData = new FormData();
        data?.skills && data?.skills?.forEach((item: any, index: number) => {
            formData.append(`skills[${index}]`, item?.value);
        });
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitportFolio = async (data: any) => {
        // console.log("skill", editid);
        let formData = new FormData();
        data.portFolio && data?.portFolio?.forEach((item: any, index: number) => {
            formData.append(`portFolio[${index}][title]`, item.title);
            formData.append(`portFolio[${index}][link]`, item.link);
            formData.append(`portFolio[${index}][imgLink]`, img1[index]);
            formData.append(`portFolio[${index}][startYear]`, item.startYear);
            formData.append(`portFolio[${index}][endYear]`, item.endYear);
        });
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    const onSubmitservice = async (data: any) => {
        // console.log("skill", editid);
        let formData = new FormData();
        // service && service.forEach((item: any, index: number) => {
        //     formData.append(`category[${index}][categoryName]`, item?.categoryName);
        //     item?.subCategories.forEach((SubItem: any, subIndex: number) => {
        //         formData.append(`category[${index}][subCategories][${subIndex}][subCategoryName]`, SubItem?.subCategoryName);
        //     })
        // })
        service && service.forEach((item: any, index: number) => {
            formData.append(`category[${index}][categoryName]`, item?.categoryName);
            formData.append(`category[${index}][_id]`, item?._id);
            item?.subCategories.forEach((SubItem: any, subIndex: number) => {
                formData.append(`category[${index}][subCategories][${subIndex}][subCategoryName]`, SubItem?.subCategoryName);
                formData.append(`category[${index}][subCategories][${subIndex}][_id]`, SubItem._id);
                SubItem.skills.forEach((skill: any, skillIndex: any) => {
                    formData.append(`category[${index}][subCategories][${subIndex}][skills][${skillIndex}][skillName]`, skill?.skillName)
                    formData.append(`category[${index}][subCategories][${subIndex}][skills][${skillIndex}][_id]`, skill?._id)
                })
            })
        })

        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoiNjViYTQzZDA4ODVlYTM0MGJiYTViYzIyIiwiZW1haWwiOiJhZG1pbkAxZXhhbXBsZTE1LmNvbSIsImlhdCI6MTcxODA5NjY3OX0.-Cv80gdtcUqlfsB7VSp7x02QkVEBtSAfQGk3--ffofE',
                },
            }
        );

        const result = await response;
        console.log("result....", result);
        navigate(`/employer`);
    }

    // Get Candidate Data
    const getSingledata = async () => {
        const { state } = location;
        const id = state?.key?.keyidd;
        setEditid(id);
        if (state != null) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getEmployerProfile?id=${id}`, {
                method: 'GET',
                headers: {
                    Accept: "application/json",
                    'Content-Type': 'application/json',
                    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
                },
            });
            const result = await response.json();
            SetCandidateImage(result.data.profileImage);
            setCompanyData(result.data)
            // console.log("received data", result.data);

            setService(result?.data?.category?.map((cat: any) => {
                return {
                    categoryName: cat.categoryName,
                    _id: cat._id,
                    subCategories: cat.subCategories.map((item: any) => item)
                }
            }))

            const { name, lastName, email, profileImage, password, profileTitle,
                mobile, aboutCompany, website, address,
                minimumHourlyRate, city, country, state, zipCode, skills,
                portFolio, projectSize,
            } = result.data;

            result?.data?.portFolio?.forEach((item: any, index: number) => {
                img1[index] = item?.imgLink;
            })

            function numberOfWords(name: any, lastName: any, email: any, profileImage: any,
                password: any, profileTitle: any, mobile: any, aboutCompany: any, website: any,
                address: any, minimumHourlyRate: any, city: any,
                country: any, state: any, zipCode: any, skills: any,
                portFolio: any, projectSize: any) {
                reset({
                    name, lastName, email, profileImage, password, profileTitle,
                    mobile, aboutCompany, website, address,
                    minimumHourlyRate: { label: minimumHourlyRate, value: minimumHourlyRate }, city: { label: city, value: city },
                    projectSize: { label: projectSize, value: projectSize },
                    country: { label: country, value: country },
                    state: { label: state, value: state },
                    skills: skills?.map((item: any) => { return { label: item, value: item } }),
                    zipCode: zipCode,

                    portFolio: portFolio?.map((item: any) => ({
                        'title': item?.title, 'link': item?.link, 'imgLink': item?.imgLink, 'startYear': item?.startYear, 'endYear': item?.endYear
                    })),

                })
            }

            numberOfWords(name, lastName, email, profileImage, password,
                profileTitle, mobile, aboutCompany, website, address,
                minimumHourlyRate, city, country, state, zipCode, skills,
                portFolio, projectSize);
        }
    }
    useEffect(() => {
        getSingledata();
    }, [reset]);

    const getAdditionalData = async () => {
        const categoryData = await getCategory();
        setJobSCategoryData(categoryData)
    }

    useEffect(() => {
        getAdditionalData();
    }, [])

    return (
        <>
            <section className={`${st.pageWrapper} pb-4`}>
                <div className={`${st.pageTitle}`}>
                    <Row>
                        <Col md={4} lg={4}>
                            <div className={`${st.titleInfo}`}>
                                <img src={studentIcon} className={`${st.icon}`} />
                                <div className={`${st.titleInfoBody}`}>
                                    <h5>Update Candidate</h5>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} lg={4}>
                            <ul className={`${st.bridSection}`}>
                                <li>
                                    <NavLink to="/candidate"> Candidate</NavLink>
                                </li>
                                <li>
                                    <MdOutlineNavigateNext className={`${cx.icon}`} />
                                </li>
                                <li>
                                    <span>Add</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>

                <div className={`${st.pageWrapperInside}`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col md={4} lg={3} xl={2}>
                                            <div className={`${st.uploadForm} me-3 mb-3`}>
                                                <div className={`${st.fileUpload} position-relative`}>
                                                    {/* <input onChange={(e)=>handleFileSelect(e)} type="file" {...register("profileImage")} /> */}
                                                    <input
                                                        type="file"
                                                        {...register("profileImage")}
                                                        onChange={(e) => {
                                                            register("profileImage").onChange(e);
                                                            handleFileSelect(e);
                                                        }}
                                                    />
                                                    {
                                                        candidateImage && !candidateTempImage ?
                                                            <img src={candidateImage} className={`${st.icon}`} /> :
                                                            <img src={candidateTempImage} className={`${st.icon}`} />
                                                    }
                                                    {/* <div className="position-absolute" style={{ zIndex: "99", top: "0px", right: "0px" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </div> */}
                                                </div>
                                                <Form.Label>
                                                    Upload Photo<span className="text-danger">*</span>
                                                </Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={12} lg={9} xl={10}>
                                            <Col md={12} lg={12} xl={12}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className={`${st.formField}`}>
                                                            <Form.Label>
                                                                {" "}
                                                                First Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control type="text"  {...register("name")} placeholder=" First Name" />
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col lg={6}>
                                                        <Form.Group className={`${st.formField}`}>
                                                            <Form.Label>
                                                                Last Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control type="text" {...register("lastName")} placeholder="Last Name" />
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col lg={6}>
                                                        <Form.Group className={`${st.formField}`}>
                                                            <Form.Label>
                                                                Email<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control type="email" {...register("email")} placeholder="Email" />
                                                        </Form.Group>
                                                    </Col>
                                                    <div className="d-flex justify-content-end py-2">
                                                        <Form.Label className={`${st.labelHide}`}></Form.Label>
                                                        <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitData)}>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            profileTitle<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control type="text"  {...register("profileTitle")} placeholder=" Profile Title" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            mobile<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control type="text"  {...register("mobile")} placeholder=" +911234567890" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            website<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control type="text"  {...register("website")} placeholder=" Website" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            aboutCompany<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <textarea style={{ height: "150px" }} className="form-control" {...register("aboutCompany")} placeholder="aboutCompany" />
                                                    </Form.Group>
                                                </Col>

                                                <div className="d-flex justify-content-end py-2">
                                                    <Form.Label className={`${st.labelHide}`}></Form.Label>
                                                    <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitDataAvailability)}>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <Row>

                                                <Col lg={6}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            Hourly Rate<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Controller
                                                            name="minimumHourlyRate"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    // isSearchable={false}
                                                                    className="react-dropdown type_employer"
                                                                    classNamePrefix="dropdown"
                                                                    options={hourlyRateOption}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            Project Size<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Controller
                                                            name="projectSize"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    // isSearchable={false}
                                                                    className="react-dropdown type_employer"
                                                                    classNamePrefix="dropdown"
                                                                    options={projectSizeData}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <div className="d-flex justify-content-end py-2">
                                                    <Form.Label className={`${st.labelHide}`}></Form.Label>
                                                    <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitAddress)}>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col lg={8}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            Address<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control type="text"  {...register("address")} placeholder="Address" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            Zip Code<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Form.Control type="number"  {...register("zipCode")} placeholder="Zip Code" />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            Country<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Controller
                                                            name="country"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    // isSearchable={false}
                                                                    className="react-dropdown type_employer"
                                                                    classNamePrefix="dropdown"
                                                                    options={countryOptions}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            State<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    // isSearchable={false}
                                                                    className="react-dropdown type_employer"
                                                                    classNamePrefix="dropdown"
                                                                    options={stateOptions}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Group className={`${st.formField}`}>
                                                        <Form.Label>
                                                            {" "}
                                                            City<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <Controller
                                                            name="city"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    // isSearchable={false}
                                                                    className="react-dropdown type_employer"
                                                                    classNamePrefix="dropdown"
                                                                    options={cityOptions}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <div className="d-flex justify-content-end py-2">
                                                    <Form.Label className={`${st.labelHide}`}></Form.Label>
                                                    <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitskills)}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className={`${st.formField}`}>
                                                <Form.Label>
                                                    {" "}
                                                    Skills<span className="text-danger">*</span>
                                                </Form.Label>
                                                <Controller
                                                    name="skills"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isMulti
                                                            // isSearchable={false}
                                                            className="react-dropdown type_employer"
                                                            classNamePrefix="dropdown"
                                                            options={serviceSkills}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <div className="d-flex justify-content-end py-2">
                                            <Form.Label className={`${st.labelHide}`}></Form.Label>
                                            <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitservice)}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className={`${st.formField}`}>
                                                <Form.Label>
                                                    {" "}
                                                    Categories and Sub Categories<span className="text-danger">*</span>
                                                </Form.Label>
                                                <div className="accordion " id="accordionPanelsStayOpenExample">
                                                    {
                                                        jobSCategoryData?.map((item: any, cindex: number) =>
                                                            <div className="accordion-item border-0" >
                                                                <h2 className="accordion-header mb-0" id={`heading${item?.categoryName}`}>
                                                                    <button className="accordion-button services_navItems " onClick={() => setShowHideAccordion(item)} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item?.categoryName}`} aria-expanded="true" aria-controls={`collapse${item?.categoryName}`}>
                                                                        {item?.categoryName}
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapse${item?.categoryName}`} className={`accordion-collapse collapse ${showAccordion?.includes(item) ? "show" : ""}`} aria-labelledby={`heading${item?.categoryName}`} data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body   services_navItems_contant ">
                                                                        <div className="">
                                                                            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                                                                                <Row className="d-flex w-100 ">

                                                                                    <Nav variant="pills"  >
                                                                                        {
                                                                                            item?.subCategories?.map((sub: any, sindex: number) => {
                                                                                                const checkSubCat = JSON.stringify(service).includes(sub?._id)
                                                                                                console.log(checkSubCat, JSON.stringify(service), sub, "oooooooooo");

                                                                                                return <Col md={6}>
                                                                                                    <Nav.Item >
                                                                                                        <div className={`text-black  py-2 position-relative d-flex align-items-center text-start `} key={`${sindex}`}>
                                                                                                            <input
                                                                                                                checked={checkSubCat}
                                                                                                                onChange={(e) => selectCategory(e, { cname: item?.categoryName, cid: item?._id }, sub)} value={sub.subCategoryName} className="me-3" style={{ height: "20px", width: "20px" }} type="checkbox" name={sub.subCategoryName} id="" />
                                                                                                            {sub.subCategoryName}
                                                                                                        </div>
                                                                                                    </Nav.Item>
                                                                                                </Col>
                                                                                            }

                                                                                            )
                                                                                        }

                                                                                    </Nav>
                                                                                </Row>
                                                                            </Tab.Container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <div className="d-flex justify-content-end py-2">
                                            <Form.Label className={`${st.labelHide}`}></Form.Label>
                                            <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className={`${st.pageWrapperInside} mt-4`}>
                    <div className={`${st.contentBox} p-3`} style={{ backgroundColor: "rgb(241,243,246)" }}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmitportFolio)}>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className={`${st.formField}`}>
                                                <Form.Label>
                                                    {" "}
                                                    Portfolio<span className="text-danger">*</span>
                                                </Form.Label>
                                                <div className="bg-white card-box my-0 px-0 py-3 border-20 list_data">
                                                    <ul>
                                                        {fieldsPortFolio.map((item, index) => (
                                                            <li key={item.id}>
                                                                <div className="accordion-body">
                                                                    <div className="row my-3">
                                                                        <div className="col-lg-2">
                                                                            <div className="dash-input-wrapper mb-30 md-mb-10">
                                                                                <label htmlFor="">Title</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            <div className="dash-input-wrapper mb-30">
                                                                                <input className="form-control" {...register(`portFolio.${index}.title`)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row my-3">
                                                                        <div className="col-lg-2">
                                                                            <div className="dash-input-wrapper mb-30 md-mb-10">
                                                                                <label htmlFor="">PortFolio Link</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            <div className="dash-input-wrapper mb-30">
                                                                                <input className="form-control" type="website"
                                                                                    {...register(`portFolio.${index}.link`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row my-3">
                                                                        <div className="col-lg-2">
                                                                            <div className="dash-input-wrapper mb-30 md-mb-10">
                                                                                <label htmlFor="">Year</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            <div className="row">
                                                                                <div className="col-sm-6 mb-30">
                                                                                    <input className="form-control" type="date"
                                                                                        {...register(`portFolio.${index}.startYear`)}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-6 mb-30">
                                                                                    <input className="form-control" type="date"
                                                                                        {...register(`portFolio.${index}.endYear`)}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row my-3">
                                                                        <div className="col-lg-2">
                                                                            <div className="dash-input-wrapper mb-30 md-mb-10">
                                                                                <label htmlFor="">Image Link</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-10">
                                                                            {/* <div className="dash-input-wrapper">
                                                                                <input
                                                                                    className="size-lg form-control"
                                                                                    type="file"
                                                                                    onChange={(e) => changeLinkOne(e, index)}
                                                                                />
                                                                            </div> */}
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <input
                                                                                        className="size-lg form-control"
                                                                                        type="file"
                                                                                        {...register(`portFolio.${index}.imgLink`)}
                                                                                        onChange={(e) => changeLinkOne(e, index)}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    {(companyData?.portFolio[index]?.imgLink || img1[index]) ?
                                                                                        <img style={{ height: "100px", width: "150px" }} src={img1[index] ? img1[index] : companyData?.portFolio[index].imgLink} className="rounded mx-auto d-block pt-4" alt="..." />
                                                                                        : <svg xmlns="http://www.w3.org/2000/svg" width="100" height="150" fill="currentColor" className="bi bi-image-fill" viewBox="0 0 16 16">
                                                                                        <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                                                                                      </svg>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-end mb-30">
                                                                    <Button onClick={() => deletePortfolio(index)} className={`${st.btnDisabled}`} >Delete</Button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <div className="d-flex justify-content-between py-2">
                                            <Button onClick={() => appendPortFolio({})} className={`${st.btnDisabled}`} >Add more</Button>
                                            <Button type="submit" className={`${st.btnDisabled}`} >Update</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

            </section >
        </>
    );
}