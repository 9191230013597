import axios from 'axios';

export const getIndustry = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/industry/getIndustry/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
            }
        }).then(result => {
            return result.data.data;
        });

    } catch (error) {
        console.error("Error fetching industry data:", error);
        throw error;
    }
};

export const getSkill = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/skill/getSkill/`).then(result => {
            return result.data.data;
        });

    } catch (error) {
        console.error("Error fetching industry data:", error);
        throw error;
    }
};

export const getCategory = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/category/getJobCategory/`).then(result => {
            return result.data.data;
        });

    } catch (error) {
        console.error("Error fetching industry data:", error);
        throw error;
    }
};

export const getImgUrl = async (api: string, body: any) => {
    return await axios
        .post(`${process.env.REACT_APP_API_URL}/${api}/`, body, {
            headers: {
                Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ',
            },
        })
        .then((response) => {
            return response.data;
        });
};