import * as React from "react";
import {useCallback,useEffect,useState}  from 'react';
import {GridColDef, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const StatusButton = (props: any) => {
  let { params } = props;
  return (
    <div>
      <ul className={`${tb.actionTable}`}>
        <li>
          <NavLink className={`btn ${tb.edit}`} title="Edit" to="#">
            <MdEdit />
          </NavLink>
        </li>
        <li>
          <NavLink className={`btn ${tb.delete}`} title="Delete" to="#">
            <AiFillDelete />
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const columns: GridColDef[]  = [
  { field: "srNumber", headerName: "Sr No.", flex: 1, minWidth: 60 },
  { field: "name", headerName: "Name", flex: 1, minWidth: 120 },
  { field: "email", headerName: "Email", flex: 1, minWidth: 180 },
  {field: "action",headerName: "Action",flex: 1, minWidth: 100,renderCell: (params: any) => <StatusButton params={params} />,
  },
];


export default function AddCompanyDatagrid() {
  const [row, setRow] = useState([]);

  const navigate = useNavigate();
  const handleCellClick = (params:any) => {
    const field = params.field;
    // const id = params.id;
    const dataid = params.id
    // console.log(field);
    // console.log(dataid);
   
    // if ( field ==='srNumber' ||field === 'name' || field ==='email') {
    //   navigate(`/hr/details`,  { state: dataid } );
    // }
  };


  const getData = async () => { 

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getEmployerProfile`, {
      method: 'get',
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });
    const result = await response.json();
    // console.log("result...", result);

    const rowdata = result.data
    .filter((item: any) => item.employerType === "hr")
    .map((item:any,index:any)=>{

      return { 
             id:item._id,
             srNumber:index+1,
             name:item.name,
             email:item.email,
         }
     });
     console.log("Hr", rowdata);
     setRow(rowdata);
  }
  
  useEffect(() => {
    getData();
  }, []);





  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        // onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
