
import React, { useCallback, useState,useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Jobs.module.scss";
import Select from "react-select";
import { useForm , Controller} from "react-hook-form";
import { useLocation } from "react-router-dom";


import tb from "../../../datatable.module.scss";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import {
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineNavigateNext,
} from "react-icons/md";

// --------- Images --------- //
import studentIcon from "../../../images/icon-student.svg";
import uploadFile from "../../../images/iconUpload.svg";
import PhoneNumber from "../../../components/Phonenumber/Phonenumber";
import Checkbox from "../../../components/Forms/Checkbox";
import { AiFillDelete, AiFillHome, AiOutlineHome } from "react-icons/ai";
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

interface Option {
  value: string;
  label: string;
}

export default function AddCandidate() {


  const [resumeFile, setResumeFile] = useState<any>();
  const [jobSkill, setjobSkill] = useState([]);
  const [jobCategory, setjobCategory] = useState([]);
  const [industryArray, setindustryArray] = useState([]);
  const [employerIdArray, setemployerIdArray] = useState([]);


  const[editid, setEditid]= useState();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue,control, reset,formState: { errors }, } = useForm();
  const location = useLocation();



  const onSubmit = async(data:any)=>{
      console.log("editiddd", editid);

             
  const addUser = { 
    jobTitle: `${data.jobTitle} `, 
    employerId: data.employerName.id, 
    // employerId: data._id, 
    jobDescription: data.jobDescription, 
    // jobCategory: data.jobCategory.value,
    jobCategory: data.jobCategory.id,

    jobType:data.jobType.value,
    salary:data.salary,
    // skills: data.skills.value,
    skillId: data.skills.map((item:any) => item.id).join(","),
    experience: data.experience.value,
    location: data.location,
    // industry: data.industry.value,
    industry: data.industry.id,
    // industry:  data.industry.map((item:any) => item.id).join(","),
    language: data.language,
    uploadFile: resumeFile,
    city: data.city,
    state: data.state,
    country: data.country.value,
    pinCode: data.pinCode,

   }
  //  console.log("reactHook", addUser);
  

    if(editid){
      // console.log("we are updating exist data");
       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/updateJob/${data.id}`,{
         method: "PATCH",
         headers:{
            Accept: "application/json",
           'Content-Type': 'application/json',
           Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
         },
       
         body: JSON.stringify(addUser),
       });

         const result = await response.json();
        //  console.log("result....", result);
         navigate(`/jobs`);
     }
    
   else{
    // console.log("we are post new job");
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/createJob`, {
    headers:{
      'Content-Type': 'application/json',
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
    },
    method: "POST",
    body: JSON.stringify(addUser),
  });

    const result = await response.json();
    // console.log("result....", result);
       navigate(`/jobs`);
   }


  }


  // for get singleuser

  const getSingledata = async () => { 
    const { state: locationState } = location;
    if(locationState!= null){
    const id=  locationState.key.keyidd;
    setEditid(id);
    console.log("single user id",id);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getJob?_id=${id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      },
    });
    const result = await response.json();     
               
    console.log("received data",result.data);
    const{jobTitle, jobDescription,salary,city,pinCode,location,jobType,employerId,jobCategory,experience,industry,state,country,skillId,uploadFile} = result.data[0];


    let industry1 = industry.industryName;
    let jobCategory1 = jobCategory.name;
  
    const skillNames = skillId.map((skill:any) => ({ id: skill.id,skillName:skill.skillName}));
    // console.log("skillNames",skillNames);
    // console.log("industry1",industry1);
    // console.log("uploadFile",uploadFile);




   reset({id,jobTitle,jobDescription,salary,city,pinCode,location,state,uploadFile,
    jobType:{label:jobType,value:jobType},
    experience:{label:experience,value:experience},
    industry:{label:industry1,value:industry1},
    // uploadFile:{label:uploadFile,value:uploadFile},
    jobCategory:{label:jobCategory1,value:jobCategory1},
    country:{label:country,value:country},

    skills: skillNames.map((skill: any) => ({
      id:skill.id,
      value: skill.skillName,
      label: skill.skillName
    }))
  
  })

 }
  }
 

  useEffect(() => {
    getSingledata();
   },[reset]);
    




const getSkillList = async () => { 

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/skill/getSkill/`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
    },
  });
  const result = await response.json();     

       const jobskillnames1 = result.data.map((item:any,index:any)=> {
        return{
          id:item._id,
          value: item.skillName,
          label: item.skillName,
        }
      
      });
      // console.log("vbvgc",jobcategorynames11);
     setjobSkill(jobskillnames1);
}

const getJobCategoryList = async () => { 

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/category/getJobCategory/`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
    },
  });
  const result = await response.json();     

       const jobcategorynames = result.data.map((item:any,index:any)=> {
        return{
          id:item._id,
          value: item.name,
          label: item.name,
        }
      
      });
      // console.log("vbvgc",jobcategorynames);
      setjobCategory(jobcategorynames);
}
const getIndustryList = async () => { 

  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/industry/getIndustry/`, {
  method: 'GET',
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json',
    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
  },
});
const result = await response.json();     

     const Industrynames = result.data.map((item:any,index:any)=> {
      return{
        id:item._id,
        value: item.industryName,
        label: item.industryName,
      }
    
    });
    // console.log("vbvgc",Industrynames);
    setindustryArray(Industrynames);
}
const getEmployerList = async () => { 

  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getEmployerProfile/`, {
  method: 'GET',
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json',
    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
  },
});
const result = await response.json();     

     const employernames = result.data.map((item:any,index:any)=> {
      return{
        id:item._id,
        value: item.name,
        label: item.name,
      }
    
    });
    // console.log("vbvgc",employernames);
    setemployerIdArray(employernames);
}


  useEffect(() => {
    getSkillList();
    getJobCategoryList();
    getIndustryList();
    getEmployerList();
   },[]);
  //  console.log("jobSkill",jobSkill)


const jobcategorynames: readonly jobs[] = [
  { value: "Oliver Hansen", label: "Oliver Hansen" },
  { value: "Henry", label: "Henry" },
];

// for jobtype
interface jobs {
  readonly value: string;
  readonly label: string;
  // readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}
const jobTypeData: readonly jobs[] = [
  { value: "Hourly-Contract", label: "Hourly-Contract" },
  { value: "Fixed-Price", label: "Fixed-Price" },
];

//  for experience
interface experiencetype {
  readonly value: string;
  readonly label: string;
  // readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const experienceTypeData: readonly experiencetype[] = [
  { value: "Intermediate", label: "Intermediate" },
  { value: "No-Experience", label: "No-Experience" },
  { value: "Expert", label: "Expert" },
];

//  for Countries
interface countrytype {
  readonly value: string;
  readonly label: string;
  // readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const countryTypeData: readonly countrytype[] = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States of America", label: "United States of America" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }


];


const handleFileSelect = (event: any) => {
  const file = event.target.files[0];
  // console.log(file.name, "file.name");
  if (file) {
    setResumeFile(file.name);
  }
};

const divStyle = {
  border: '1px solid grey',
  borderRadius:"8px",
  backgroundColor:"#FFFAFA",
  marginBottom:'8px',
  padding:'8px',
  width:'100%',
  height: '40px',
  display: 'flex', 
  alignItems: 'center' 
};


  return (
    <>
      <section className={`${st.pageWrapper} `} >
        <div className={`${st.pageTitle}`}>
          <Row>

            <Col md={4} lg={4}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Add Jobs</h5>
                  <p>Create a new job</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <ul className={`${st.bridSection}`}>
                <li>
                  <NavLink to="/jobs"> Jobs</NavLink>
                </li>
                <li>
                  <MdOutlineNavigateNext className={`${cx.icon}`} />
                </li>
                <li>
                  <span>Add</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>


        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className={`${st.contentBox}`}>
                <Row >

                  <Col md={12} lg={12} xl={12}>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <h2 >Job details</h2>
                        <Col lg={6} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              Job Title<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("jobTitle", { required: true })} placeholder="Job Title" />
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Employer Name<span className="text-danger">*</span>
                            </Form.Label>
                           
                            <Controller
                                  name="employerName"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={employerIdArray}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                    }}
                                  />
                            )}
                          />
                            </Form.Group>
                        </Col>

                        <Col lg={12} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Job Description<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control 
                             as="textarea"
                             rows={4}
                            type="text" {...register("jobDescription")} 
                            // rows ={4}
                            placeholder=" Job Description" 
                            style={{ minHeight: '100px' }} />
          
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Job Category<span className="text-danger">*</span>
                            </Form.Label>

                            <Controller
                                  name="jobCategory"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      // value={jobCategoryDefault}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={jobCategory}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                      // setjobCategoryDefault(event);
                                    }}
                                  />
                            )}
                          />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                          <Form.Label>
                              Job Type<span className="text-danger">*</span>
                            </Form.Label>
                          <Controller
                                name="jobType"
                               control={control}
                               rules={{
                                  required: true,
                                }}
                             render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={jobTypeData}
                                onChange={(event) => {
                                  // setJobTypeDefault(event);
                                  field.onChange(event); // Update the field value
                                  
                                }}
                            />
                           )}
                       />
                          </Form.Group>
                        </Col>
                          
                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                                <Form.Label htmlFor="">Salary*</Form.Label>
                                 <Form.Control type="number"  {...register("salary", {
                                 min: { value: 1000, message: "Salery must be greater then 1000" },
                               required: `salary is required!`
                              })} />
                            </Form.Group>
                        </Col>
                        
                     </Row>
                    <br />
                    <br />

                    <Row>
                          <h2>Skills & Experience</h2>

                          <Col lg={12}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Skills<span className="text-danger">*</span>
                            </Form.Label>

                            <Controller
                                  name="skills"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  // defaultValue={skills}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isMulti
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={jobSkill}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                    }}
                                  />
                            )}
                          />
                            </Form.Group>
                          </Col>


                          <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Experience<span className="text-danger">*</span>
                            </Form.Label>

                            <Controller
                                  name="experience"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      // value={experienceDefault || field.value}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={experienceTypeData}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                      // setexperienceDefault(event);
                                    }}
                                  />
                            )}
                          />
                            </Form.Group>
                          </Col>


                          {/* <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Location<span className="text-danger">*</span>
                            </Form.Label>

                            <Controller
                                  name="location"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={jobcategorynames}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                    }}
                                  />
                            )}
                          />
                            </Form.Group>
                          </Col> */}

                          <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              Location<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("location", { required: true })} placeholder="Location" />
                          </Form.Group>
                        </Col>

                          <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Industry<span className="text-danger">*</span>
                            </Form.Label>

                            <Controller
                                  name="industry"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      //  value={industryDefault}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={industryArray}
                                      onChange={(event) => {
                                      // console.log(event, "event");
                                      field.onChange(event); // Update the field value
                                      // setindustryDefault(event);
                                      
                                    }}
                                   
                                  />
                            )}
                          />
                            </Form.Group>
                          </Col>

                          <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              Language<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("language", { required: true })} placeholder="Language" />
                          </Form.Group>
                        </Col>
                         
                    </Row>

                    <br />
                    <br />

                    <Row>
                    <h2>File Attachement</h2>
                      <Col lg={6}>
                    <Form.Group className={`${st.formField}`}>
                         <Form.Label>
                         Resume<span className="text-danger">*</span>
                       </Form.Label>
                       <br />
                       <div className="attached-file" style={divStyle}>
                                <span>{(resumeFile !== undefined && resumeFile !== null)
                                ? resumeFile : "MyCvResume.PDF"}</span>
                          </div>
                        {/* <Form.Control 
                        type="text"  
                        style={divStyle}
                        {...register("MyResume", { required: true })} 
                        value={resumeFile}
                        placeholder="MyResume.CV Pdf" /> */}

                    <input 
                    type="file"
                    style={{ color: 'transparent' }}
                   {...register("uploadFile", { required: `File is required!`, onChange: (event) => handleFileSelect(event) })}
                  />
                      </Form.Group>
                      </Col>
                    </Row>


                    <br />
                    <br />
                            
                
                    <Row>
                    <h2>Location</h2>

                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              City<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("city", { required: true })} placeholder="City" />
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              State<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("state", { required: true })} placeholder="State" />
                          </Form.Group>
                        </Col>

                     <Col lg={6}>
                     <Form.Group className={`${st.formField}`}>
                       <Form.Label>
                         Country<span className="text-danger">*</span>
                       </Form.Label>
                     
                       <Controller
                             name="country"
                             control={control}
                             rules={{
                               required: true,
                             }}
                             render={({ field }) => (
                               <Select
                                 {...field}
                                 isSearchable={false}
                                 className="react-dropdown"
                                 classNamePrefix="dropdown"
                                 options={countryTypeData}
                                 onChange={(event) => {
                                 // console.log(event, "event");
                                 field.onChange(event); // Update the field value
                               }}
                             />
                       )}
                     />
                   </Form.Group>
                    </Col>
                    
                    <Col lg={6}>
                          <Form.Group className={`${st.formField}`}>
                                <Form.Label htmlFor="">Pincode*</Form.Label>
                                 <Form.Control type="number"  {...register("pinCode", {
                               required: `pincode is required!`
                              })} />
                            </Form.Group>
                    </Col>

                  </Row>

                      <Col lg={6} md={12} className={`${st.formField} text-end`} >
                          <Form.Label className={`${st.labelHide}`}>.</Form.Label>
                          <Button type="submit" className={`${st.btnDisabled}`} >Save</Button>
                        </Col>

                    </Form>



                  </Col>
                </Row>
              </div>


            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}

