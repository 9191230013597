import React, { useCallback, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Employer.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

import StudentMenu from "../../../components/Sidebar/StudentMenu";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import studentIcon from "../../../images/icon-student.svg";
import EmployerDataGrid from "./AddEmployerDataGrid";

export default function Employer() {
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <StudentMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Employer</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink to="/employer/add" className={`btn ${tb.addBtn}`}>
                      <img src={studentIcon} className={`${tb.icon}`} />
                      Add Employer
                    </NavLink>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <EmployerDataGrid />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
