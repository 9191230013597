import * as React from "react";
import {useCallback,useEffect,useState}  from 'react';
import {GridColDef, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const StatusButton = (props: any) => {
  let { params } = props;
  const [urlid, setUrlid] = useState();
  const [open, setOpen] = useState(false);

  const keyidd = params.id;
  // console.log("keyidd",keyidd);

  const handleClickOpen = (id:any) => {
    setUrlid(id);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async(id:any)=> {
    console.log("deleteidd", id);
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/deleteJob/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });
  
      const result = await response.json();
      // console.log("deleted data", result);
  
      setOpen(false);
      window.location.reload();
    }

  return (
    <div>
      <ul className={`${tb.actionTable}`}>
        <li>
          <NavLink className={`btn ${tb.edit}`} title="Edit"  to={{ pathname:'/jobs/add'}} state={{key: {keyidd}}} >
            <MdEdit />
          </NavLink>
        </li>
        <li>
          <NavLink className={`btn ${tb.delete}`} title="Delete"  onClick={() => handleClickOpen (keyidd)}   to="#">
            <AiFillDelete />
          </NavLink>
          <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="dialog-title"
                  aria-describedby="dialog-description"
                >
                  <DialogTitle id="dialog-title">
                    {"Are you sure to delete the data"}
                  </DialogTitle>
           
                  <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button  className="card_link" onClick={() => handleDelete(urlid)} autoFocus>
                         Yes
                    </Button>
                  </DialogActions>
          
                </Dialog>
        </li>
      </ul>
    </div>
  );
};

const columns: GridColDef[]  = [
  { field: "srNumber", headerName: "Sr No.", flex: 1, minWidth: 80 },
  { field: "jobTitle", headerName: "Job Title", flex: 1,minWidth: 240  },
  { field: "jobCategory", headerName: "Job Category", flex: 1,minWidth: 260  },
  { field: "jobtype", headerName: "Job Type", flex: 1,minWidth: 200  },
  { field: "skill", headerName: "Skills", flex: 1,minWidth: 300  },
  { field: "experience", headerName: "Experience", flex: 1,minWidth: 200  },
  { field: "location", headerName: "Location", flex: 1,minWidth: 180  },
  { field: "industry", headerName: "Industry Name", flex: 1, minWidth: 240 },
  { field: "applyIdLength", headerName: "Applied jobs", flex: 1, minWidth: 180 },
  {field: "action",headerName: "Action",flex: 1, minWidth: 160,renderCell: (params: any) => <StatusButton params={params} />,
  },
];


export default function AddCompanyDatagrid() {
  const [row, setRow] = useState([]);

  const navigate = useNavigate();
  const handleCellClick = (params:any) => {
    const field = params.field;
    // const id = params.id;
    const dataid = params.id
    // console.log(field);
    // console.log(dataid);
   
    // if ( field ==='srNumber' ||field === 'name' || field ==='email') {
    //   navigate(`/jobs/details`,  { state: dataid } );
    // }
  };


  const getData = async () => { 

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getJob`, {
      method: 'get',
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });
    const result = await response.json();
    // console.log("result...", result);

    const rowdata = result.data.map((item:any,index:any)=>{
      let jobcat = item.jobCategory ? item.jobCategory.name : '';
      let industrycat = item.industry ? item.industry.industryName : '';
    //   console.log(jobcat);
   let skillnameee = item.skillId.map((skill: any) => skill.skillName).join(', ');
  //  console.log(skillnameee);
  // let industrycat = item.industry.map((industry: any) => industry.industryName).join(', ');
  // console.log(industrycat);
      return { 
             id:item.id,
             srNumber:index+1,
             jobTitle:item.jobTitle,
             jobCategory:jobcat,
             jobtype:item.jobType,
             skill:skillnameee,
             experience:item.experience,
             location:item.location,
             industry: industrycat,
             applyIdLength:item.applyIdLength,
           
         }
     });
     console.log("Jobs", rowdata);
     setRow(rowdata);
  }
  
  useEffect(() => {
    getData();
  }, []);





  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        // onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
