import * as React from "react";
import {GridColDef, DataGrid,GridToolbar } from '@mui/x-data-grid';
import {useCallback,useEffect,useState}  from 'react';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import {MdEdit} from "react-icons/md";
import {AiFillDelete} from "react-icons/ai";
import { useLocation } from "react-router-dom";

// const StatusButton = (props:any) => {
//   let { params } = props;
//   return (
//     <div>
//       <ul className={`${tb.actionTable}`}>
//         <li>
//           <NavLink className={`btn ${tb.edit}`} title="Edit" to="#">
//             <MdEdit />
//           </NavLink>
//         </li>
//         <li>
//           <NavLink className={`btn ${tb.delete}`} title="Delete" to="#">
//             <AiFillDelete />
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   );
// };


const columns: GridColDef[]  = [
    { field: "srNumber", headerName: "Sr No",minWidth:40,},
    { field: "name",headerName: "Name",flex:1,headerAlign: 'center', align:'center' , minWidth:150,},
    // { field: "action",headerName: "Action",minWidth:150,renderCell: (params:any) => <StatusButton params={params} />,},
  ];



  interface ChildProps {
    message: string;
  }

  const AddCompaniesDatagrid: React.FC<ChildProps> = ({ message }) => {
    console.log("current tab",message);
    const tab1= "tabId1";
    const tab2= "tabId2";
 
// export default function AddCompaniesDatagrid() {

  const location = useLocation();
  const dataid = location.state;
  console.log("dataid",dataid);

  const [row, setRow] = useState([]);

   
  const getCategoriesData = async () => { 

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/industry/getIndustry/${dataid}`, {
      method: 'get',
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });
    const result = await response.json();
    console.log("result...", result);


         const categories = result.data.category;
         const rowdata1 = categories.map((category:any, index:any) => ({
         id: index+1,
         name: category
       }));

         const subcategories = result.data.subCategory;
         const rowdata2 = subcategories.map((subCategory:any, index:any) => ({
         id: index+1,
         name: subCategory
       }));
// console.log("rowdata2",rowdata2);

if (message === tab1) {
    // console.log("i am tab1")
  setRow(rowdata1);
} else if (message === tab2) {
  // console.log("i am tab2")
  setRow(rowdata2);
}


   
  }
  
  useEffect(() => {
    getCategoriesData();
  }, [message,tab1,tab2]);




  return (
    <div style={{ width: '100%' }}>
       {row.length === 0 ? (
        <p>No data </p>
      ) : (
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      )}
      </div>
  );
}

export default AddCompaniesDatagrid;