import * as React from "react";
import { GridColDef,DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import {useEffect,useState}  from 'react';
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import {MdEdit} from "react-icons/md";
import {AiFillDelete} from "react-icons/ai";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const StatusButton = (props:any) => {

  const navigate = useNavigate();
  let{params} = props;
  // console.log(params.row);
  const keyidd = params.id;
  // console.log("keyidd",keyidd);
  // const industryName = params.row.industryName;


  const [urlid, setUrlid] = useState();
  const [open, setOpen] = useState(false);
      
  const handleClickOpen = (id:any) => {
    setUrlid(id);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

      // for delete the row
 const handleDelete = async(id:any)=> {
  console.log("deleteidd", id);

  //  console.log("iddd", data.id);
   const data2={"id":id}
   console.log("iddd", data2);

  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/industry/deleteIndustry/${id}`, {
    
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
    },
    body: JSON.stringify(data2),
  });

    const result = await response.json();
    console.log("deleted data", result);

    setOpen(false);
    window.location.reload();
  }

  return (
    <div>
      <ul className={`${tb.actionTable}`}>
        <li>
          <NavLink className={`btn ${tb.edit}`} title="Edit" to={{ pathname:'/industry/add'}} state={{key: {keyidd}} }>
            <MdEdit />
          </NavLink>
        </li>
        <li>
          <NavLink className={`btn ${tb.delete}`} title="Delete" onClick={() => handleClickOpen (keyidd)}   to="#">
            <AiFillDelete />
          </NavLink>
          <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="dialog-title"
                  aria-describedby="dialog-description"
                >
                  <DialogTitle id="dialog-title">
                    {"Are you sure to delete the data"}
                  </DialogTitle>
           
                  <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button  className="card_link" onClick={() => handleDelete(urlid)} autoFocus>
                         Yes
                    </Button>
                  </DialogActions>
          
                </Dialog>
        </li>
      </ul>
    </div>
  );
};

const columns: GridColDef[]  = [
  { field: "srNumber", headerName: "Sr No",minWidth:40,},
  { field: "industryName",headerName: "Industry Name",flex:1,headerAlign: 'center', align:'center' , minWidth:150,},
  { field: "action",headerName: "Action",minWidth:150,renderCell: (params:any) => <StatusButton params={params} />,},
];



export default function SkillDataGrid() {
  const navigate = useNavigate();

    const [row, setRow] = useState([]);

    const handleCellClick = (params:any) => {
      const field = params.field;
      // console.log(field);
      const dataid = params.id
  
      if ( field ==='srNumber' ||field === 'industryName' ) {
        navigate(`/industry/details`,  { state: dataid } );
      }
    };

    const getData = async () => { 

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/industry/getIndustry`, {
        method: 'get',
        headers: {
          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
        }
      });
      const result = await response.json();
      console.log("result...", result);

      const rowdata = result.data.map((item:any,index:any)=>{
        return { 
               id:item._id,
               srNumber:index+1,
               industryName:item.industryName,
           }
       });
      
       setRow(rowdata);

    
    }
    
    useEffect(() => {
      getData();
    }, []);
    // console.log("dataa" , row);
  

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={row}
        // onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      </div>
  );
}

