import React from "react";
import { Control, useFieldArray, UseFormRegister } from "react-hook-form";
import {
    Card,
    Row,
    Col,
    Form,
    Button,
    Tab,
    Nav,
    InputGroup,
} from "react-bootstrap";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default ({ nestIndex, control, register }: {
    nestIndex: number, register: UseFormRegister<{
        categoryName: string;
        subCategories: {
            subCategoryName: string;
            skills: {
                skillName: string;
            }[];
        }[];
    }>, control: Control<{
        categoryName: string;
        subCategories: {
            subCategoryName: string;
            skills: {
                skillName: string;
            }[];
        }[];
    }>
}) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `subCategories.${nestIndex}.skills`
    });

    return (
        <div>

            <Row>
                {fields.map((item, k) => {
                    return (
                        <div key={item.id} style={{ marginLeft: 20 }}>
                            <Col className="d-flex align-items-center gap-3" md={6} >

                                <Form.Group className={`${st.formField} w-100`}>
                                    <Form.Label>
                                        {" "}
                                        Skill {k + 1}<span className="text-danger"></span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        {...register(`subCategories.${nestIndex}.skills.${k}.skillName`, {
                                            required: true
                                        })}
                                        placeholder="Sub Category"
                                    />
                                </Form.Group>
                                <Button onClick={() => remove(k)} type="button" className={`${st.btnDisabled}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                    </svg>
                                </Button>
                            </Col>
                            {/* 
                        <label>Nested Array:</label>
                        <input
                            {...register(`subCategories.${nestIndex}.skills.${k}.skillName`, {
                                required: true
                            })}
                            style={{ marginRight: "25px" }}
                        />
                        <button type="button" onClick={() => remove(k)}>
                            Delete Nested
                        </button> */}
                        </div>
                    );
                })}
                <Col
                    md={12}
                    className={`${st.formField} d-flex justify-content-between my-3`}
                >
                    <Button onClick={() =>
                        append({
                            skillName: ''
                        })
                    } type="button" className={`${st.btnDisabled} mt-2`}>Add SKills</Button>
                </Col>
            </Row>

            {/* <button
                type="button"
                onClick={() =>
                    append({
                        skillName: ''
                    })
                }
            >
                Append Nested
            </button> */}
        </div >
    );
};


{/* */ }