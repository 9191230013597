import React, { useCallback, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Industry.module.scss";
import tb from "../../../datatable.module.scss";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { BsFacebook, BsTelegram } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import {
  MdEdit,
  MdLocationPin,
  MdModeEditOutline,
  MdOutlineCheck,
} from "react-icons/md";

// --------- Images --------- //

import course from "../../../images/icon-course.svg";
import materialicon from "../../../images/material.svg";
import DemoInfo from "../../../components/DemoInfo/DemoInfo";
import studenticons from "../../../images/studenticon.svg";
import {
  AiFillDelete,
  AiFillHome,
  AiOutlinePlus,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import StudentFilter from "../../../components/Sidebar/StudentFilter";
import EnrollNewStudentM from "../../../components/Modals/EnrollNewStudentM";
import IndustryCategoriesDatagrid from "./IndustryCategoriesDatagrid";
// import ResumeCandidate from "./ResumeCandidate";


// interface ParentProps {
//   message: any;
// }

export default function CandidateDetails() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [activeTab, setActiveTab] = useState("tabId1");
 
  const handleTabChange = (eventKey:any) => {
  setActiveTab(eventKey);  
  };
  // console.log("Active Tab:", activeTab);

  const stylemargin={
    marginLeft:"100px"
  }

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`} style={stylemargin}>

           <br />
           <br />
        <div className={`${st.pageWrapperInside}`}>
    <Card>
        <Card.Body>
         <div className={`${st.tabbingList}`}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabId1" activeKey={activeTab}  onSelect={handleTabChange}>
                  <Nav variant="pills">
                  
                    <Nav.Item>
                      <Nav.Link eventKey="tabId1">Categories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tabId2">SubCategories</Nav.Link>
                    </Nav.Item>
                   
                  </Nav>

                  <div className={`${st.contentBox}`}>
                    <Tab.Content>

                      <Tab.Pane eventKey="tabId1">
                       <div className={`${tb.dataTable}`}>
                        <IndustryCategoriesDatagrid message={activeTab}/>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="tabId2">
                     <div className={`${tb.dataTable}`}>
                        <IndustryCategoriesDatagrid message={activeTab}/>
                        </div>
                      </Tab.Pane>
    
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div> 
        </Card.Body>
    </Card>
    </div>

      </section>
      <EnrollNewStudentM show={show} handleClose={handleClose} />
    </>
  );
}
