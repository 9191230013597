
import React, { useCallback, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Resume.module.scss";
import tb from "../../../datatable.module.scss";



import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiOutlineInfoCircle, AiFillDelete } from "react-icons/ai";
import { FiUpload, FiDownload } from "react-icons/fi";

import StudentMenu from "../../../components/Sidebar/StudentMenu";


// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import studentIcon from "../../../images/icon-student.svg";
import AddJobsDatagrid from "./AddJobsDatagrid";


export default function Resume() {
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <StudentMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Jobs</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink
                      to="/jobs/add"
                      className={`btn ${tb.addBtn}`}
                    >
                      <img src={studentIcon} className={`${tb.icon}`} />
                      Add Jobs
                    </NavLink>

                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <AddJobsDatagrid />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}


// import React, { useCallback, useState,useEffect } from "react";
// import { useParams,useNavigate } from "react-router-dom";
// import st from "../../../style.module.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// import cx from "./Jobs.module.scss";
// import Select from "react-select";
// import { useForm , Controller} from "react-hook-form";
// import { useLocation } from "react-router-dom";


// import tb from "../../../datatable.module.scss";
// import {
//   Card,
//   Row,
//   Col,
//   Form,
//   Button,
//   Tab,
//   Nav,
//   InputGroup,
// } from "react-bootstrap";
// import { NavLink } from "react-router-dom";

// import {
//   MdContentCopy,
//   MdEdit,
//   MdLocationPin,
//   MdOutlineCheck,
//   MdOutlineNavigateNext,
// } from "react-icons/md";

// // --------- Images --------- //
// import studentIcon from "../../../images/icon-student.svg";
// import uploadFile from "../../../images/iconUpload.svg";
// import PhoneNumber from "../../../components/Phonenumber/Phonenumber";
// import Checkbox from "../../../components/Forms/Checkbox";
// import { AiFillDelete, AiFillHome, AiOutlineHome } from "react-icons/ai";
// import DemoInfo from "../../../components/DemoInfo/DemoInfo";


// interface jobs {
//   readonly value: string;
//   readonly label: string;
//   // readonly color: string;
//   readonly isFixed?: boolean;
//   readonly isDisabled?: boolean;
// }
// const jobTypeData: readonly jobs[] = [
//   { value: "Hourly-Contract", label: "Hourly-Contract" },
//   { value: "Fixed-Price", label: "Fixed-Price" },
// ];

// export default function AddCandidate() {

 
//   // const [error, setError] = useState();
//   // const[editid, setEditid]= useState();
//   // const navigate = useNavigate();
//   const [resumeFile, setResumeFile] = useState<any>()
//   const { register, handleSubmit, control, reset,formState: { errors }, } = useForm();
//   // const location = useLocation();


//   const onSubmit = async(data:any)=>{
//   //     console.log("editiddd", editid);

//   //     const addUser = { 
//   //       name: `${data.firstname} ${data.lastname}`, 
//   //       email: data.email, 
//   //       password: data.password, 
//   //       type: 'candidate',
//   //      }
//   //     console.log("reactHook", addUser);

     
//   //   if(editid){
//   //      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/updateProfile/${data.id}`,{
//   //        method: "PATCH",
//   //        headers:{
//   //           Accept: "application/json",
//   //          'Content-Type': 'application/json',
//   //          Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
//   //        },
       
//   //        body: JSON.stringify(addUser),
//   //      });

//   //        const result = await response.json();
//   //        console.log("result....", result);
//   //        navigate(`/candidate`);
//   //    }
    
//   //  else{
//   //   const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/register`, {
//   //     headers:{
//   //       'Content-Type': 'application/json',
//   //       Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
//   //     },
//   //     method: "POST",
//   //     body: JSON.stringify(addUser),
//   //   });

//   //     const result = await response.json();
//   //     console.log("result....", result);
//   //        navigate(`/candidate`);
//   //  }

//        const addUser = { 
//         jobtitle: `${data.jobtitle} `, 
//         jobdescription: data.jobdescription, 
//         jobcategory: data.jobCategory.value,
//         jobtype:data.jobType.value,
//         // salary:data.salary,
//         // skills: data.skills.value,
//         // experience: data.experience.value,
//         // location: data.location.value,
//         // industry: data.industry.value,
//         // city: data.city.value,
//         // state: data.state.value,
//         // country: data.country.value,
//         // pincode: data.pincode,

//        }
//        console.log("reactHook", addUser);
//   }


//   // for get singleuser


// //   const getSingledata = async () => { 
// //     const { state } = location;
// //     if(state!= null){
// //     const id= state.key.keyidd;
// //     setEditid(id);
// //     console.log("single user id",id);

// //       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getProfile?id=${id}`, {
// //       method: 'GET',
// //       headers: {
// //         Accept: "application/json",
// //         'Content-Type': 'application/json',
// //         Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
// //       },
// //     });
// //     const result = await response.json();     
               
// //     console.log("received data",result.data);
// //     const{name,email,password} = result.data;

      
// //   function numberOfWords(str:any,email:any,password:any) { 
// //        const words = str.match(/\S+/g); 
// //        if(words.length==1){ 
// //            var firstname = str;
// //            console.log(id);
// //            console.log(firstname);
// //            console.log(email);
// //            console.log(password);
// //            reset({firstname,email,password})
// //        } 
// //        else{ 
// //             var names = str.split(' ');
// //             var firstname = names[0];
// //             var lastname = names[1];
// //             console.log(id);
// //             console.log(firstname);
// //             console.log(lastname); 
// //             console.log(email);
// //             console.log(password);
         
// //             reset({id,firstname, lastname,email,password})
// //        } 
// //   } 
 
// //   numberOfWords(name,email,password);   
// //  }
// //   }
// //   useEffect(() => {
// //     getSingledata();
// //    },[reset]);
  
// // const jobcategorynames = [
// //   'Oliver Hansen',
// //   'Van Henry',
// //   'April Tucker',
// //   'Ralph Hubbard',
// //   'Omar Alexander',
// //   'Carlos Abbott',
// //   'Miriam Wagner',
// //   'Bradley Wilkerson',
// //   'Virginia Andrews',
// //   'Kelly Snyder',
// // ];
// const jobcategorynames: readonly jobs[] = [
//   { value: "Oliver Hansen", label: "Oliver Hansen" },
//   { value: "Henry", label: "Henry" },
// ];

// const handleFileSelect = (event: any) => {
//   const file = event.target.files[0];
//   console.log(file.name, "file.name");
//   if (file) {
//     setResumeFile(file.name);
//   }
// };

//   return (
//     <>
//       <section className={`${st.pageWrapper} `} >
//         <div className={`${st.pageTitle}`}>
//           <Row>

//             <Col md={4} lg={4}>
//               <div className={`${st.titleInfo}`}>
//                 <img src={studentIcon} className={`${st.icon}`} />
//                 <div className={`${st.titleInfoBody}`}>
//                   <h5>Add Jobs</h5>
//                   <p>Create a new job</p>
//                 </div>
//               </div>
//             </Col>
//             <Col md={4} lg={4}>
//               <ul className={`${st.bridSection}`}>
//                 <li>
//                   <NavLink to="/jobs"> Jobs</NavLink>
//                 </li>
//                 <li>
//                   <MdOutlineNavigateNext className={`${cx.icon}`} />
//                 </li>
//                 <li>
//                   <span>Add</span>
//                 </li>
//               </ul>
//             </Col>
//             <Col md={4} lg={4}>
//               <div className="d-flex justify-content-end">
//                 <DemoInfo />
//               </div>
//             </Col>
//           </Row>
//         </div>


//         <div className={`${st.pageWrapperInside}`}>
//           <Card>
//             <Card.Body>
//               <div className={`${st.contentBox}`}>
//                 <Row >

//                   <Col md={12} lg={12} xl={12}>

//                     <Form onSubmit={handleSubmit(onSubmit)}>
//                       <Row>
//                         <h2 >Job details</h2>
//                         <Col lg={12} md={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               {" "}
//                               Job Title<span className="text-danger">*</span>
//                             </Form.Label>
//                             <Form.Control type="text"  {...register("jobtitle", { required: true })} placeholder="Job Title" />
//                           </Form.Group>
//                         </Col>

//                         <Col lg={12} md={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Job Description<span className="text-danger">*</span>
//                             </Form.Label>
//                             <Form.Control type="text" {...register("jobdescription")} 
//                             // rows ={4}
//                             placeholder=" Job Description" />
          
//                           </Form.Group>
//                         </Col>

//                         <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Job Category<span className="text-danger">*</span>
//                             </Form.Label>
//                             {/* <Form.Select aria-label="Default select example">
//                               {jobcategorynames.map((item:any, index:any) => (
//                                 <option key={index} value={item}>
//                                   {item}
//                                 </option>
//                               ))}
//                             </Form.Select> */}

//                             <Controller
//                                   name="jobCategory"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                         </Col>

//                         <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                           <Form.Label>
//                               Job Type<span className="text-danger">*</span>
//                             </Form.Label>
//                           <Controller
//                                 name="jobType"
//                                control={control}
//                                rules={{
//                                   required: true,
//                                 }}
//                              render={({ field }) => (
//                               <Select
//                                 {...field}
//                                 isSearchable={false}
//                                 className="react-dropdown"
//                                 classNamePrefix="dropdown"
//                                 options={jobTypeData}
//                                 onChange={(event) => {
//                                   // console.log(event, "event");
//                                   field.onChange(event); // Update the field value
//                                 }}
//                             />
//                            )}
//                        />
//                             {/* <Form.Label>
//                               Email<span className="text-danger">*</span>
//                             </Form.Label> */}
//                             {/* <Form.Control type="email" {...register("email", { required: true })} placeholder="Email"  /> */}
//                             {/* <select {...register("Title", { required: true })}>
//                                      <option value="Mr">Mr</option>
//                                       <option value="Mrs">Mrs</option>
//                                      <option value="Miss">Miss</option>
//                                        <option value="Dr">Dr</option>
//                                         </select> */}
//                           </Form.Group>
//                         </Col>
                          
//                         <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                                 <Form.Label htmlFor="">Salary*</Form.Label>
//                                  <Form.Control type="number"  {...register("salary", {
//                                  min: { value: 1000, message: "Salery must be greater then 1000" },
//                                required: `salary is required!`
//                               })} />
//                             </Form.Group>
//                         </Col>
                        
//                      </Row>
//                     <br />
//                     <br />

//                     <Row>
//                           <h2>Skills & Experience</h2>

//                           <Col lg={12}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Skills<span className="text-danger">*</span>
//                             </Form.Label>

//                             <Controller
//                                   name="skills"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                           </Col>


//                           <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Experience<span className="text-danger">*</span>
//                             </Form.Label>

//                             <Controller
//                                   name="experience"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                           </Col>


//                           <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Location<span className="text-danger">*</span>
//                             </Form.Label>

//                             <Controller
//                                   name="location"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                           </Col>

//                           <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Industry<span className="text-danger">*</span>
//                             </Form.Label>

//                             <Controller
//                                   name="industry"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                           </Col>


//                           <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                             <Form.Label>
//                               Language<span className="text-danger">*</span>
//                             </Form.Label>

//                             <Controller
//                                   name="jobCategory"
//                                   control={control}
//                                   rules={{
//                                     required: true,
//                                   }}
//                                   render={({ field }) => (
//                                     <Select
//                                       {...field}
//                                       isSearchable={false}
//                                       className="react-dropdown"
//                                       classNamePrefix="dropdown"
//                                       options={jobcategorynames}
//                                       onChange={(event) => {
//                                       // console.log(event, "event");
//                                       field.onChange(event); // Update the field value
//                                     }}
//                                   />
//                             )}
//                           />
//                             </Form.Group>
//                           </Col>
//                     </Row>

//                     <br />
//                     <br />

//                     <Row>
//                     <h2>File Attachement</h2>
//                       <Col lg={6}>
//                   <Form.Group className={`${st.formField}`}>
//                        <Form.Label>
//                          Resume<span className="text-danger">*</span>
//                        </Form.Label>
//                        <br />
//                        {/* <Form.Control type="text"  {...register("filerequired", { required: true, onChange: (event) => handleFileSelect(event) })} placeholder="Job Title" /> */}
//                     <input
//                     type="file"
//                    {...register("uploadFile", { required: `File is required!`, onChange: (event) => handleFileSelect(event) })}
//                      />
//                       </Form.Group>
//                       </Col>
//                     </Row>


//                     <br />
//                     <br />
                            
                
//                     <Row>
//                     <h2>Location</h2>

//                      <Col lg={6}>
//                      <Form.Group className={`${st.formField}`}>
//                        <Form.Label>
//                          City<span className="text-danger">*</span>
//                        </Form.Label>
                     
//                        <Controller
//                              name="city"
//                              control={control}
//                              rules={{
//                                required: true,
//                              }}
//                              render={({ field }) => (
//                                <Select
//                                  {...field}
//                                  isSearchable={false}
//                                  className="react-dropdown"
//                                  classNamePrefix="dropdown"
//                                  options={jobcategorynames}
//                                  onChange={(event) => {
//                                  // console.log(event, "event");
//                                  field.onChange(event); // Update the field value
//                                }}
//                              />
//                        )}
//                      />
//                    </Form.Group>
//                     </Col>

//                      <Col lg={6}>
//                      <Form.Group className={`${st.formField}`}>
//                        <Form.Label>
//                          State<span className="text-danger">*</span>
//                        </Form.Label>
                     
//                        <Controller
//                              name="state"
//                              control={control}
//                              rules={{
//                                required: true,
//                              }}
//                              render={({ field }) => (
//                                <Select
//                                  {...field}
//                                  isSearchable={false}
//                                  className="react-dropdown"
//                                  classNamePrefix="dropdown"
//                                  options={jobcategorynames}
//                                  onChange={(event) => {
//                                  // console.log(event, "event");
//                                  field.onChange(event); // Update the field value
//                                }}
//                              />
//                        )}
//                      />
//                    </Form.Group>
//                     </Col>

//                      <Col lg={6}>
//                      <Form.Group className={`${st.formField}`}>
//                        <Form.Label>
//                          Country<span className="text-danger">*</span>
//                        </Form.Label>
                     
//                        <Controller
//                              name="country"
//                              control={control}
//                              rules={{
//                                required: true,
//                              }}
//                              render={({ field }) => (
//                                <Select
//                                  {...field}
//                                  isSearchable={false}
//                                  className="react-dropdown"
//                                  classNamePrefix="dropdown"
//                                  options={jobcategorynames}
//                                  onChange={(event) => {
//                                  // console.log(event, "event");
//                                  field.onChange(event); // Update the field value
//                                }}
//                              />
//                        )}
//                      />
//                    </Form.Group>
//                     </Col>
                    
//                     <Col lg={6}>
//                           <Form.Group className={`${st.formField}`}>
//                                 <Form.Label htmlFor="">Pincode*</Form.Label>
//                                  <Form.Control type="number"  {...register("pincode", {
//                                required: `pincode is required!`
//                               })} />
//                             </Form.Group>
//                     </Col>

//                 </Row>

//                       <Col lg={6} md={12} className={`${st.formField} text-end`} >
//                           <Form.Label className={`${st.labelHide}`}>.</Form.Label>
//                           <Button type="submit" className={`${st.btnDisabled}`} >Save</Button>
//                         </Col>

//                     </Form>



//                   </Col>
//                 </Row>
//               </div>


//             </Card.Body>
//           </Card>
//         </div>
//       </section>
//     </>
//   );
// }










// // import React, { useCallback, useState } from "react";
// // import st from "../../../style.module.scss";
// // import "bootstrap/dist/css/bootstrap.min.css";
// // import cx from "./JobType.module.scss";
// // import tb from "../../../datatable.module.scss";
// // import { Card, Row, Col } from "react-bootstrap";
// // import { NavLink } from "react-router-dom";

// // import { AiOutlineInfoCircle, AiFillDelete } from "react-icons/ai";
// // import { FiUpload, FiDownload } from "react-icons/fi";

// // import StudentMenu from "../../../components/Sidebar/StudentMenu";
// // import AddJobs from "./AddJobs";

// // // --------- Images --------- //
// // import DemoInfo from "../../../components/DemoInfo/DemoInfo";

// // import studentIcon from "../../../images/icon-student.svg";

// // export default function JobType() {
// //   return (
// //     <>
// //       <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
// //         <StudentMenu />
// //         <div className={`${st.pageTitle}`}>
// //           <Row>
// //             <Col md={6} lg={6}>
// //               <div className={`${st.titleInfo}`}>
// //                 <img src={studentIcon} className={`${st.icon}`} />
// //                 <div className={`${st.titleInfoBody}`}>
// //                   <h5>Students</h5>
// //                   <p>Your students</p>
// //                 </div>
// //               </div>
// //             </Col>
// //             <Col md={6} lg={6}>
// //               <DemoInfo />
// //             </Col>
// //           </Row>
// //         </div>

// //         <div className={`${st.pageWrapperInside}`}>
// //           <Card>
// //             <Card.Body>
// //               <Row className={`${tb.tableAction}`}>
// //                 <Col lg={12}>
// //                   <div className="d-flex">
// //                     <NavLink
// //                       to="/job-type/add"
// //                       className={`btn ${tb.addBtn}`}
// //                     >
// //                       <img src={studentIcon} className={`${tb.icon}`} />
// //                       Add Job Type
// //                     </NavLink>
// //                     <button className={`btn ${tb.upload}`}>
// //                       <FiUpload className={`${tb.icon}`} />
// //                       Upload CSV
// //                       <input type="file" />
// //                     </button>

// //                     <button className={`btn ${tb.upload}`}>
// //                       <AiFillDelete className={`${tb.icon} m-0`} />
// //                     </button>
// //                   </div>
// //                 </Col>
// //               </Row>
// //               <div className={`${tb.dataTable}`}>
// //                 <AddJobs />
// //               </div>
// //             </Card.Body>
// //           </Card>
// //         </div>
// //       </section>
// //     </>
// //   );
// // }
