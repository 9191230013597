
import React from "react";
import { useNavigate } from "react-router-dom";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import {
    Card,
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import axios from "axios";



const Login = () => {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();


    const onSubmit = async (data: any) => {

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/loggin`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        if (response.data.status) {
            localStorage.setItem("token", response?.data?.token);
            localStorage.setItem("id", response?.data?.data?._id);
            navigate(`/admin`);
        }else{
            alert(response?.data?.message)
        }
        console.log(response);
    };
    // api/v1/admin/register
    return (
        <>
            <section>
                <div className={`${st.pageWrapperInside}`} style={{ height: "100vh", width: "100vw", backgroundColor: "rgb(241,243,246)" }}>
                    <div className={`${st.contentBox} d-flex justify-content-center p-2 `} style={{ width: "100vw" }}>
                        <Card className="w-75">
                            <Card.Title className="p-3">
                                <h2>LogIn</h2>
                            </Card.Title>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Col md={12}>
                                        <Form.Group className={`${st.formField}`}>
                                            <Form.Label>
                                                Email<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control type="email" {...register("email", { required: true })} placeholder="Email" />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className={`${st.formField}`}>
                                            <Form.Label>
                                                Password<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control type="password" {...register("password", { required: true })} placeholder="Password" />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className={`${st.formField} text-end`} >
                                        <Form.Label className={`${st.labelHide}`}>.</Form.Label>
                                        <Button type="submit" className={`${st.btnDisabled}`} >Save</Button>
                                    </Col>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section >
        </>
    );
};

export default Login;
