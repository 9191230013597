import React, { useCallback, useState,useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Employer.module.scss";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";


import tb from "../../../datatable.module.scss";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import {
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineNavigateNext,
} from "react-icons/md";

// --------- Images --------- //
import studentIcon from "../../../images/icon-student.svg";
import uploadFile from "../../../images/iconUpload.svg";
import PhoneNumber from "../../../components/Phonenumber/Phonenumber";
import Checkbox from "../../../components/Forms/Checkbox";
import { AiFillDelete, AiFillHome, AiOutlineHome } from "react-icons/ai";
import DemoInfo from "../../../components/DemoInfo/DemoInfo";



export default function AddEmployer() {

 
  const [error, setError] = useState();
  const[editid, setEditid]= useState();
  const navigate = useNavigate();
  const { register, handleSubmit, reset,formState: { errors }, } = useForm();
  const location = useLocation();


  const onSubmit = async(data:any)=>{
      console.log("editiddd", editid);

      const addUser = {  
        email: data.email, 
        password: data.password, 
        type: 'employer',
       }
      console.log("reactHook", addUser);


     
    if(editid){
       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/updateEmployerProfile/${editid}`,{
         method: "PATCH",
         headers:{
            Accept: "application/json",
           'Content-Type': 'application/json',
           Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
         },
       
         body: JSON.stringify(addUser),
       });

         const result = await response.json();
         console.log("result....", result);
         navigate(`/employer`);
     }
    
   else{
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/register`, {
      headers:{
        'Content-Type': 'application/json',
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      },
      method: "POST",
      body: JSON.stringify(addUser),
    });

      const result = await response.json();
      console.log("result....", result);
         navigate(`/employer`);
   }

  }


  // for get singleuser


  const getSingledata = async () => { 
    const { state } = location;
    if(state!= null){
    const id= state.key.keyidd;
    setEditid(id);
    console.log("single user id",id);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getEmployerProfile?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      },
    });
    const result = await response.json();     
               
    console.log("received data",result.data);
    const{name,email,password,mobile} = result.data;

      
  function numberOfWords(str:any,email:any,password:any,mobile:any) { 
       const words = str.match(/\S+/g); 
       if(words.length==1){ 
           var firstname = str;
           console.log(id);
           console.log(firstname);
           console.log(email);
           console.log(password);
           reset({firstname,email,password,mobile})
       } 
       else{ 
            var names = str.split(' ');
            var firstname = names[0];
            var lastname = names[1];
            console.log(id);
            console.log(firstname);
            console.log(lastname); 
            console.log(email);
            console.log(password);
         
            reset({id,firstname, lastname,email,password,mobile})
       } 
  } 
 
  numberOfWords(name,email,password,mobile);   
 }
  }
  useEffect(() => {
    getSingledata();
   },[reset]);
  

  return (
    <>
      <section className={`${st.pageWrapper} `} >
        <div className={`${st.pageTitle}`}>
          <Row>

            <Col md={4} lg={4}>
              <div className={`${st.titleInfo}`}>
                <img src={studentIcon} className={`${st.icon}`} />
                <div className={`${st.titleInfoBody}`}>
                  <h5>Add Employer</h5>
                  <p>Create a new employer</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <ul className={`${st.bridSection}`}>
                <li>
                  <NavLink to="/employer"> Employer</NavLink>
                </li>
                <li>
                  <MdOutlineNavigateNext className={`${cx.icon}`} />
                </li>
                <li>
                  <span>Add</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>


        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className={`${st.contentBox}`}>
                <Row>

                  <Col md={12} lg={12} xl={12}>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>

                        {/* <Col lg={6} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              {" "}
                              First Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text"  {...register("firstname", { required: true })} placeholder=" First Name" />
                          </Form.Group>
                        </Col>

                        <Col lg={6} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Last Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" {...register("lastname")} placeholder="Last Name" />
                          </Form.Group>
                        </Col>

                        <Col lg={4} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Mobile<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" {...register("mobile", { required: true })} placeholder="Mobile"  />
                          </Form.Group>
                        </Col> */}

                        <Col lg={4} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Email<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="email" {...register("email", { required: true })} placeholder="Email"  />
                          </Form.Group>
                        </Col>

                        <Col lg={4} md={6}>
                          <Form.Group className={`${st.formField}`}>
                            <Form.Label>
                              Password<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="password" {...register("password", { required: true })} placeholder="Password"  />
                          </Form.Group>
                        </Col>

                        <Col lg={4} md={12} className={`${st.formField} text-end`} >
                          <Form.Label className={`${st.labelHide}`}>.</Form.Label>
                          <Button type="submit" className={`${st.btnDisabled}`} >Save</Button>
                        </Col>

                      </Row>
                    </Form>



                  </Col>
                </Row>
              </div>


            </Card.Body>
          </Card>
        </div>



      </section>
    </>
  );
}
