import React from "react";
import cx from "./Sidebar.module.scss";
import { NavLink } from "react-router-dom";

// --------- Images --------- //
import studentIcon from "../../images/icon-student.svg";
import { FaUserGraduate } from "react-icons/fa";
import { MdGridView } from "react-icons/md";

const StudentMenu = () => {
  return (
    <>
      <div className={`${cx.moreMenuList}`}>
        <h5 className={`${cx.moreMenuTitle}`}>
          <div className={`${cx.menuIcon}`}>
            <img src={studentIcon} />
          </div>
          <div className={`${cx.menuName}`}>Data Listing</div>
        </h5>
        <ul>
          <li>
            <NavLink to="/login">
              <div className={`${cx.menuIcon}`}>
                <img src={studentIcon} />
              </div>
              <div className={`${cx.menuName}`}>Login</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/">
              <div className={`${cx.menuIcon}`}>
                <img src={studentIcon} />
              </div>
              <div className={`${cx.menuName}`}>Student(Example)</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/employer">
              <div className={`${cx.menuIcon}`}>
                <img src={studentIcon} />
              </div>
              <div className={`${cx.menuName}`}>Employer</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/candidate">
              <div className={`${cx.menuIcon}`}>
                <img src={studentIcon} />
              </div>
              <div className={`${cx.menuName}`}>Candidate</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/company">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>Company</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/hr">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>HR</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/jobs">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>Jobs</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/job-category">
              <div className={`${cx.menuIcon}`}>
                <img src={studentIcon} />
              </div>
              <div className={`${cx.menuName}`}>Job Category</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/job-type">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>Job Type</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/skills">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>Skills</div>
            </NavLink>
          </li>

          <li>
            <NavLink to="/industry">
              <div className={`${cx.menuIcon}`}>
                <FaUserGraduate />
              </div>
              <div className={`${cx.menuName}`}>Industry</div>
            </NavLink>
          </li>

         

        </ul>
      </div>
    </>
  );
};

export default StudentMenu;
