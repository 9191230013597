import * as React from "react";
import { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const columns = [
  { field: "srNumber", headerName: "Sr No", flex: 1, minWidth: 40, },
  // { field: "profileImage", headerName: "profileImage", flex: 1, minWidth: 180, },
  {
    field: 'profileImage',
    headerName: 'profileImage',
    width: 100,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <Avatar alt={params.row.name} src={params.value} />
        {/* <Box ml={2}>{params.row.name}</Box> */}
      </Box>
    ),
  },
  { field: "name", headerName: "Full Name", flex: 1, minWidth: 180, },
  { field: "email", headerName: "Email", flex: 1, minWidth: 180, },
  { field: "mobile", headerName: "mobile", flex: 1, minWidth: 180, },
  { field: "dob", headerName: "dob", flex: 1, minWidth: 180, },
  { field: "gender", headerName: "gender", flex: 1, minWidth: 180, },
  { field: "address", headerName: "address", flex: 1, minWidth: 180, },
  { field: "city", headerName: "city", flex: 1, minWidth: 180, },
  { field: "state", headerName: "state", flex: 1, minWidth: 180, },
  { field: "country", headerName: "country", flex: 1, minWidth: 180, },
  { field: "zipCode", headerName: "zipCode", flex: 1, minWidth: 180, },
  { field: "createdAt", headerName: "createdAt", flex: 1, minWidth: 180, },
  { field: "updatedAt", headerName: "updatedAt", flex: 1, minWidth: 180, },
  { field: "action", headerName: "Action", flex: 1, minWidth: 80, renderCell: (params: any) => <StatusButton params={params} />, },
];



const StatusButton = (props: any) => {

  const navigate = useNavigate();
  let { params } = props;
  // console.log(params.row);
  const keyidd = params.id;

  // console.log("keyidd",keyidd);

  const [urlid, setUrlid] = useState();
  const [open, setOpen] = useState(false);



  const handleClickOpen = (id: any) => {
    setUrlid(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // for delete the card
  const handleDelete = async (id: any) => {
    console.log("deleteidd", id);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/deleteProfile/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });

    const result = await response.json();
    console.log("deleted data", result);

    setOpen(false);
    window.location.reload();
  }

  return (
    <div>
      <ul className={`${tb.actionTable}`}>
        <li>
          <NavLink className={`btn ${tb.edit}`} title="Edit" to={{ pathname: '/candidate/edit' }} state={{ key: { keyidd } }}>
            <MdEdit />
          </NavLink>
        </li>
        <li>
          <NavLink className={`btn ${tb.delete}`} title="Delete" onClick={() => handleClickOpen(keyidd)} to="#">
            <AiFillDelete />
          </NavLink>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogTitle id="dialog-title">
              {"Are you sure to delete the data"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button className="card_link" onClick={() => handleDelete(urlid)} autoFocus>
                Yes
              </Button>
            </DialogActions>

          </Dialog>
        </li>
      </ul>
    </div>
  );
};



export default function CandidateDataGrid() {

  const [row, setRow] = useState([]);

  const navigate = useNavigate();
  // const handleRowClick = (params:any) => {
  //       navigate(`/candidate/details`);
  // }

  const handleCellClick = (params: any) => {
    const field = params.field;
    // const id = params.id;
    const dataid = params.id
    // console.log(field);
    // console.log(dataid);

    if (field === 'srNumber' || field === 'name' || field === 'email') {
      navigate(`/candidate/details`, { state: dataid });
    }
  };


  const getData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/getProfile`, {
      method: 'get',
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWMxOGI5NmMzMzhkY2E1YjAwYzQ5NTUiLCJlbWFpbCI6ImFwcGljdGVzdGVtcDFAZ21haWwuY29tIiwiaWF0IjoxNzA3NzI3MDM1fQ.B9SkZMx0jc7JBjhWrZMZzpS3h0-0nd4AOdONp6VwxUQ'
      }
    });
    const result = await response.json();
    const rowdata = result.data.map((item: any, index: any) => {
      return {
        id: item?._id,
        srNumber: index + 1,
        name: `${item?.name + " " + (item?.lastName ? item?.lastName : "")}`,
        email: item?.email,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        dob: item?.dob,
        gender: item?.gender,
        mobile: item?.mobile !== 'undefined' ?item?.mobile:"-",
        profileImage: item?.profileImage,
        address: item?.address,
        city: item?.city,
        country: item?.country,
        state: item?.state,
        zipCode: item?.zipCode,
      }
    });

    if (rowdata && rowdata.length > 0) {
      setRow(rowdata);
    } else {
      console.log("no data");
    }
  }

  useEffect(() => {
    getData();
  }, []);
  console.log("dataa", row);



  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={row}
        // onCellClick={handleRowClick}
        onCellClick={handleCellClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );

}

