import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import cx from "./Navigation.module.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";

export default function Navigation() {
    let location = useLocation();
    console.log(window.location.href, location, "useNavigate")
    return <Fragment>
        <div className={`${location.pathname.includes('/login') ? cx.wrapperLogin : cx.wrapper}`}>
            {
                location.pathname.includes('/login')?
                    <Fragment></Fragment> : <Header />
            }
            {location.pathname.includes('/login')? <Fragment></Fragment> : <Sidebar />}

            <Outlet />
            {location.pathname.includes('/login')? <Fragment></Fragment> : <Footer />}
        </div>

        <div className={`${cx.mobileHide}`}>
            <p>This Platform is not working in mobile.</p>
            <p>Please Continue in Desktop or Tablets.</p>
            <p>Thanks</p>
        </div>
    </Fragment>;
}
